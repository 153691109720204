import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <StandardPage>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </StandardPage>
)

export default NotFoundPage
